import React, { useState, FormEvent, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { FormGroup, InputGroup, Button, Tabs, Tab, Checkbox, Collapse, HTMLSelect, HTMLTable, ControlGroup, TextArea, Classes, Alert } from "@blueprintjs/core";

import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next';

import { Environment } from './Types'

interface EnvironmentViewProps {
    env: Environment,
    baseUrl?: string,
    token?: string | null,
    onNew?: () => void,
    onEdit?: () => void,
    onCopy?: () => void,
    onDelete?: (env: Environment) => void,
}

function EnvironmentView({ env = {} as Environment, baseUrl = "", token = "", onEdit = () => { }, onNew = () => { }, onCopy = () => { }, onDelete = () => {} }: EnvironmentViewProps) {
    const { t, i18n } = useTranslation();
    const [isOpenDelete, setIsOpenDelete] = useState(false)

    if (!("envId" in env)) return null

    return (
        <div>
            <Alert icon="trash" intent="danger" confirmButtonText={t('button_delete')} isOpen={isOpenDelete} onConfirm={() => {onDelete(env); setIsOpenDelete(false)}} onCancel={() => setIsOpenDelete(false)} cancelButtonText={t('button_no')} canEscapeKeyCancel={true} canOutsideClickCancel={true}>
                <p>{t('confirm_delete_environment', {env})}</p>
            </Alert>
            
            <div className="Row">
                {/*<Button className="Col-1-1" icon="add" onClick={() => onNew()}>{t('new')}</Button>*/}
                <Button className="Col-1-1" icon="edit" onClick={() => onEdit()}>{t('edit')}</Button>
                {/*<Button className="Col-3-1" icon="duplicate" onClick={() => onCopy()}>{t('make_copy')}</Button>*/}
            </div>
            
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-1" label={t('environment')}>
                    <InputGroup readOnly value={env.envName} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label={t('company_name')}>
                    <InputGroup readOnly value={env.companyName} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label={t('address')}>
                    <TextArea readOnly fill rows={4} value={env.companyAddress} />
                </FormGroup>
            </div>
            {/*
            <div className="Row">
                <FormGroup className="Col-1-1" label={t('start_of_year')}>
                    <InputGroup readOnly value={env.startOfYear} />
                </FormGroup>
                <FormGroup className="Col-2-1" label={t('end_of_year')}>
                    <InputGroup readOnly value={env.endOfYear} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label={t('current_earnings')}>
                    <InputGroup readOnly value={env.acEarning} />
                </FormGroup>
            </div>
            */}
            {/*
            <div className="Spacing-V-16" />
            <div className="Row">
                <Button className="Col-1-1" icon="trash" onClick={() => setIsOpenDelete(!isOpenDelete)}>{t('delete')}</Button>
            </div>
            */}
        </div>
    )
}

interface EnvironmentEditProps {
    env?: Environment,
    isNew?: boolean,
    onSave?: (env: Environment) => void,
    onCancel?: () => void;
}

// onChange={utils.handleStringChange(s => setEnv(prevState => Object.assign(prevState, {acName1: s})))}
function EnvironmentEdit({ env = {} as Environment, isNew = false, onSave = () => { }, onCancel = () => { } }: EnvironmentEditProps) {
    const { t, i18n } = useTranslation();
    const { register, handleSubmit, reset, setValue } = useForm<Environment>()

    useEffect(() => {
        // Call reset to set default values if env is passed
        if ("envId" in env) {
            reset(env)
        }
    }, [env, reset])

    if (!isNew && !("envId" in env)) return null

    const onSubmit = (data: Environment) => {
        //console.log("onsubmit called", data)
        onSave(data)
    }

        const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>{t('cancel')}</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>{t('save')}</Button>
            </div>
            <div className="Spacing-V-16" />
            <InputGroup type="hidden" id="inputEnvironmentId" name="envId" inputRef={register} value={env.envId} />
            <div className="Row">
                <FormGroup className="Col-1-1" label={t('environment')} labelFor="inputEnvironmentName">
                    <InputGroup readOnly={!isNew} id="inputEnvironmentName" name="envName" inputRef={register} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label={t('company_name')} labelFor="inputCompanytName">
                    <InputGroup id="inputCompanytName" name="companyName" inputRef={register} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label={t('address')} labelFor="inputCompanyAddress">
                    <textarea className="bp3-input bp3-fill" name="companyAddress" rows={4} ref={register}></textarea>
                </FormGroup>
            </div>
            {/*
            <div className="Row">
                <FormGroup className="Col-1-1" label={t('start_of_year')} labelFor="inputStartOfYear">
                    <InputGroup type="date" id="inputStartOfYear" name="startOfYear" inputRef={register} />
                </FormGroup>
                <FormGroup className="Col-2-1" label={t('end_of_year')} labelFor="inputEndOfYear">
                    <InputGroup type="date" id="inputEndOfYear" name="endOfYear" inputRef={register} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label={t('current_earnings')} labelFor="inputAcEarning">
                    <InputGroup id="inputAcEarning" name="acEarning" inputRef={register} />
                </FormGroup>
            </div>
            */}
        </form>
    )
}

interface EnvironmentMainProps {
    svc: AxiosInstance,
    onUpdate?: () => void
}

function EnvironmentMain({ svc, onUpdate = () => {} }: EnvironmentMainProps) {
    let { tabId: defaultTabId = "list" }: { tabId: string } = useParams()
    const { t, i18n } = useTranslation();
    const history = useHistory()

    const [data, setData] = useState([] as Environment[])

    const [searchKey, setSearchKey] = useState("envName")
    const [searchValue, setSearchValue] = useState("")

    const [showFilters, setShowFilters] = useState(true)

    const [searchName, setSearchName] = useState("")
    const [searchType, setSearchType] = useState("")

    const [searchLimit, setSearchLimit] = useState(0)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState(defaultTabId) //useState("list")
    const [currentEnv, setCurrentEnv] = useState({} as Environment)

    const [currentMode, setCurrentMode] = useState("show")

    const [isOpenDuplicate, setIsOpenDuplicate] = useState(false)
    const [saveAs, setSaveAs] = useState("")
    const [copyCOA, setCopyCOA] = useState(true)
    const [copyNum, setCopyNum] = useState(true)
    const [copyTx, setCopyTx] = useState(true)

    const [isOpenRename, setIsOpenRename] = useState(false)
    const [renameTo, setRenameTo] = useState("")

    useEffect(() => {
        setSelectedTabId(defaultTabId)
    }, [defaultTabId])

    const changeTab = (tabId: string) => {
        history.push(`/environments/${tabId}`)
        setSelectedTabId(tabId)
    }

    useEffect(() => {
        loadAllEnvironments()
    }, [])

    useEffect(() => {
        if (data.length > 0) {
            let i = 0;
            if ("envName" in currentEnv) {
                i = data.findIndex(e => e.envName === currentEnv.envName)
            }
            setCurrentEnv(i > 0 ? data[i] : data[0])
        }
    }, [data])

    const loadAllEnvironments = async () => {
        try {
            const res = await svc.post('/environmentlist');

            setData(res.data.data)
            if (res.data.data.length > 0) {
                setCurrentEnv(res.data.data[0])
            } else {
                utils.showError(t('no_record'))
            }
        } catch (error) {
            utils.showError(error)
        }
    }

    const refreshEnvironments = async () => {
        try {
            const res = await svc.post('/environmentlist',
                {
                    by: searchKey,
                    start: searchValue,
                    //limit: searchLimit,
                    backward: searchBackward,
                    filters: {
                        companyName: searchName,
                        //acType: searchType,
                    }
                });

            setData(res.data.data)
            if (res.data.data.length === 0) {
                utils.showError(t('no_record'))
            }
            /*
            if (res.data.data.length > 0) {
                setCurrentEnv(res.data.data[0])
            } else {
                utils.showError(t('no_record'))
            }
            */
            //changeTab("list")
        } catch (error) {
            utils.showError(error)
        }
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()
        refreshEnvironments()
    }

    const editOnSave = async (env: Environment) => {
        try {
            const res = await svc.post('/environmentupdate', env)
            utils.showSuccess(t('saved'))
            //console.log("env saved", res.data.data)
            for (let i = 0; i < data.length; i++) {
                if (data[i].envId === res.data.data.envId) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    //setCurrentEnv(res.data.data)
                    break
                }
            }
            setCurrentEnv(res.data.data)
            setCurrentMode("show")
            onUpdate()
        } catch (error) {
            utils.showError(error)
        }
    }

    const newOnSave = async (env: Environment) => {
        try {
            const res = await svc.post('/environmentadd', env)
            utils.showSuccess(t('saved'))
            refreshEnvironments()
            /*
            for (let i = 0; i < data.length; i++) {
                if (data[i].envId === res.data.data.envId) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            */
            setCurrentEnv(res.data.data)
            setCurrentMode("show")
            onUpdate()
        } catch (error) {
            utils.showError(error)
        }
    }

    const viewOnDelete = async (env: Environment) => {
        try {
            const res = await svc.post('/environmentdelete', env)
            utils.showSuccess(t('deleted'))
            setData(data.filter(env => !(env.envId === res.data.envId)))
            setCurrentEnv({} as Environment)
            changeTab("list")
            onUpdate()
        } catch (error) {
            utils.showError(error)
        }
    }

    const onMakeCopy = async (env: Environment) => {
        try {
            const res = await svc.post('/environmentcopy', Object.assign(env, {copyCOA, copyNum, copyTx, newEnvName: saveAs}))
            utils.showSuccess(t('done'))
            refreshEnvironments()
            changeTab("list")
            onUpdate()
        } catch (error) {
            utils.showError(error)
        }
    }

    const onRename = async (env: Environment) => {
        try {
            const res = await svc.post('/environmentrename', Object.assign(env, {newEnvName: renameTo}))
            utils.showSuccess(t('done'))
            refreshEnvironments()
            changeTab("list")
            onUpdate()
        } catch (error) {
            utils.showError(error)
        }
    }

    // currentMode = show
    let detail = <EnvironmentView env={currentEnv} baseUrl={svc.defaults.baseURL} token={localStorage.getItem('token')} onCopy={() => {setSaveAs(currentEnv.envName+" Copy"); setIsOpenDuplicate(true)}} onEdit={() => setCurrentMode("edit")} onNew={() => setCurrentMode("new")} onDelete={viewOnDelete}></EnvironmentView>
    switch (currentMode) {
        case "edit":
            detail = <EnvironmentEdit env={currentEnv} onSave={editOnSave} onCancel={() => setCurrentMode("show")}></EnvironmentEdit>
            break
        case "new":
            detail = <EnvironmentEdit isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); changeTab("list") }}></EnvironmentEdit>
            break
    }

    let list = <>
        <div className="Row">
            {/*<Button className="Col-1-1" icon="add" onClick={() => { setCurrentMode("new"); changeTab("detail") }}>{t('new')}</Button>*/}
        </div>
        <div className="Spacing-V-16" />
        <HTMLTable striped interactive condensed>
            <thead>
                <tr>
                    <th className="TCol-EnvironmentName">{t('environment')}</th>
                    <th className="TCol-CompanyName">{t('company_name')}</th>
                    {/*
                    <th className="TCol-Date">{t('start')}</th>
                    <th className="TCol-Date">{t('end')}</th>
                    <th></th>
                    <th></th>
                    */}
                </tr>
            </thead>
            <tbody>
                {data.map((env, i) =>
                    <tr key={i} onDoubleClick={() => { changeTab("detail") }}>
                        <td className="TCol-EnvironmentName" onClick={() => { setCurrentEnv(env) }}>{env.envName}</td>
                        <td className="TCol-CompanyName" onClick={() => { setCurrentEnv(env) }}>{env.companyName}</td>
                        {/*
                        <td className="TCol-Date" onClick={() => { setCurrentEnv(env) }}>{env.startOfYear}</td>
                        <td className="TCol-Date" onClick={() => { setCurrentEnv(env) }}>{env.endOfYear}</td>
                        <td><Button icon={IconNames.DUPLICATE}  onClick={() => {setCurrentEnv(env); setSaveAs(env.envName+" Copy"); setIsOpenDuplicate(true)}}>{t('make_copy')}</Button></td>
                        <td><Button icon={IconNames.EDIT}  onClick={() => {setCurrentEnv(env); setRenameTo(env.envName); setIsOpenRename(true)}}>{t('rename')}</Button></td>
                        */}
                    </tr>
                )}
            </tbody>
        </HTMLTable>
    </>

    return (
        <>
            {/*
            <Alert icon="trash" intent="danger" confirmButtonText={t('button_yes')} isOpen={isOpenDuplicate} onConfirm={() => {onMakeCopy(currentEnv); setIsOpenDuplicate(false)}} onCancel={() => setIsOpenDuplicate(false)} cancelButtonText={t('button_no')} canEscapeKeyCancel={true} canOutsideClickCancel={true}>
                <p>{t('confirm_make_copy_environment', {env: currentEnv})}</p>
                <p>{t('copy')}</p>
                <Checkbox label={t('copy_coa')} checked={copyCOA} onChange={utils.handleBooleanChange(v => setCopyCOA(v))} />
                <Checkbox label={t('copy_num')} checked={copyNum} onChange={utils.handleBooleanChange(v => setCopyNum(v))} />
                <Checkbox label={t('copy_tx')} checked={copyTx} onChange={utils.handleBooleanChange(v => setCopyTx(v))} />
                <div className="Row-4">
                    <FormGroup inline className="Col-1-4" label={t('save_as')} labelFor="inputSaveAs">
                        <InputGroup id="inputSaveAs" value={saveAs} onChange={utils.handleStringChange(s => setSaveAs(s))} />
                    </FormGroup>
                </div>
            </Alert>
            <Alert icon="trash" intent="danger" confirmButtonText={t('button_yes')} isOpen={isOpenRename} onConfirm={() => {onRename(currentEnv); setIsOpenRename(false)}} onCancel={() => setIsOpenRename(false)} cancelButtonText={t('button_no')} canEscapeKeyCancel={true} canOutsideClickCancel={true}>
                <p>{t('confirm_rename_environment', {env: currentEnv})}</p>
                <div className="Row-4">
                    <FormGroup inline className="Col-1-4" label={t('rename_to')} labelFor="inputRenameTo">
                        <InputGroup id="inputRenameTo" value={renameTo} onChange={utils.handleStringChange(s => setRenameTo(s))} />
                    </FormGroup>
                </div>
            </Alert>
            */}
            <div style={{fontSize: "21px", fontWeight: 700}}>{t('environments')}</div>
            <div className="Spacing-V-16"></div>
            <form onSubmit={onSubmit}>
                <div className="Row">
                    <FormGroup className="Col-1-3" label={t('search')} labelFor="inputSearchValue">
                        <ControlGroup fill>
                            <HTMLSelect className={Classes.FIXED} value={searchKey} onChange={utils.handleStringChange(s => setSearchKey(s))}>
                                <option value="envName">{t('environment')}</option>
                            </HTMLSelect>
                            <InputGroup
                                id="inputSearchValue"
                                placeholder={t('search')}
                                value={searchValue}
                                onChange={utils.handleStringChange(s => setSearchValue(s))}
                                leftIcon="search"
                                rightElement={<Button icon={IconNames.ARROW_RIGHT} minimal type="submit"></Button>}
                            />
                        </ControlGroup>
                    </FormGroup>
                    {/*<Checkbox className="Col-4-1 Checkbox-LabeledFormGroup" label="Backward" onChange={utils.handleBooleanChange(v => setSearchBackward(v))} />*/}
                    {/*<Button className="Col-5-1 Button-LabeledFormGroup" icon="filter" onClick={() => { setShowFilters(!showFilters) }} >Filters</Button>*/}
                </div>
                <Collapse isOpen={showFilters}>
                    <div className="Row">
                        <FormGroup className="Col-1-3" label={t('company_name')} labelFor="searchName">
                            <InputGroup id="searchName" value={searchName} onChange={utils.handleStringChange(s => setSearchName(s))} />
                        </FormGroup>
                        <div className="Col-4-2 Col-H">
                            {/*
                            <FormGroup label="Type" labelFor="searchType">
                                <InputGroup className="Input-XS" id="searchType" value={searchType} onChange={utils.handleStringChange(s => setSearchType(s))} />
                            </FormGroup>
                            <FormGroup label="Limit" labelFor="searchLimit" >
                                <InputGroup className="Input-XS" id="searchLimit" value={searchLimit.toString()} onChange={utils.handleNumberChange(n => setSearchLimit(n))} />
                            </FormGroup>
                            */}
                        </div>
                    </div>
                </Collapse>
            </form>
            <Tabs onChange={(newTabId) => changeTab(newTabId as string)} selectedTabId={selectedTabId}>
                <Tab id="list" title={t('list')} panel={list} />
                <Tab id="detail" title={t('detail')} panel={detail} />
            </Tabs>
        </>
    );
}

export default EnvironmentMain