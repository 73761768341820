import React, { useState, useEffect } from 'react';
import './App.scss';

import { HashRouter as Router, Switch, Route, Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { History } from 'history'

import { Button, AnchorButton, Navbar, Alignment, Popover, Menu, MenuItem, Position, Spinner } from "@blueprintjs/core";

import Login, { LoginCredential } from './Login';
import LicenseMain from './License';
import CustomerMain from './Customer';
import VerifyMain from './Verify';
import UserMain from './User';
//import NumberMain from './Number';
import EnvironmentMain from './Environment';
import DataMaintenanceMain from './DataMaintenance';
import TaskMain from './Task';
import * as utils from "./utils";

import md5 from './md5'

import { Environment } from './Types'

import axios from 'axios';

import svgLogo from './svg/logo.svg'
import svgLicense from './svg/license.svg'
import svgFolder from './svg/folder.svg'
import svgDatabase from './svg/database.svg'
import svgReport from './svg/report.svg'
import svgPaper from './svg/paper.svg'
import svgUser from './svg/user.svg'
import svgCustomer from './svg/customer.svg'
import svgVerify from './svg/verify.svg'
import svgNumber from './svg/number.svg'
import svgTransaction from './svg/transaction.svg'
import svgTask from './svg/task.svg'
import svgTools from './svg/tools.svg'
import svgSort from './svg/sort.svg'

import i18next from 'i18next';

const svc = axios.create({
    baseURL: process.env.NODE_ENV === "development" ? 'http://localhost:8810/api' : '/api',
})
const hostId = process.env.NODE_ENV === "development" ? md5('localhost:8810').slice(-8) : md5(window.location.host).slice(-8)

/*
// try load config from server, can be a real file or a config in web server
axios.get("config.json").then((res) => {
    //console.log(res.data)
    svc.defaults.baseURL = res.data.baseUrl
}).catch(() => {
    // do nothing, use default
})
*/

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false) // useState(initIsLoggedIn)
    const [currentUser, setCurrentUser] = useState("")
    const [currentEnv, setCurrentEnv] = useState({} as Environment)
    const [envList, setEnvList] = useState([] as Environment[])

    const pingServer = () => {
        (async () => {
            try {
                await svc.post('/ping',
                    {});
                //console.log(res.data)
            } catch (error) {
                //utils.showError(error)
                //console.log(error)
            }
        })()
    }

    /* setInterval not realiable e.g. when browser is minimized
    useEffect(() => {
        pingServer()
        const interval = setInterval(pingServer, 5000)
        return () => clearInterval(interval)
    }, [])
    */

    /*
    useEffect(() => {
        pingServer()
        const worker = new Worker('worker.js')
        worker.onmessage = () => {
            pingServer()
        }
        return () => worker.terminate()
    }, [])
    */

    window.onkeydown = (e: KeyboardEvent) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // disable ctrl + s to prevent save page when user trying to submit form while lost focus
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
        }
    }

    const handleLogin = (credential: LoginCredential, history: History) => {
        (async () => {
            try {
                // usually A/E code is prefixed with C, but some user may use others e.g. Upbest uses A for A/E
                // TODO: Read A/E prefix from config
                const res = await svc.post('/login',
                    {
                        userId: credential.login,
                        password: credential.password,
                    });
                if (res.data.ok) {
                    //console.log('Bearer '+res.data.token)
                    //console.log(res.data)
                    /*
                    svc.defaults.headers = {
                        'Authorization': 'Bearer ' + res.data.token
                    }
                    */
                    svc.defaults.headers["Authorization"] = 'Bearer ' + res.data.token
                    localStorage.setItem('token', res.data.token)
                    document.cookie = `token_${hostId}=${res.data.token}`
                    setIsLoggedIn(true)
                    history.push("/")
                } else {
                    //console.log(res.data)
                    utils.showError(res.data.message)
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
    }

    const handleLogout = () => {
        svc.defaults.headers = {}
        localStorage.removeItem('token')
        document.cookie = `token_${hostId}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`
        document.cookie = `env_${hostId}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`
        setIsLoggedIn(false)
        setCurrentUser("")
        setCurrentEnv({} as Environment)
    }

    const handleChangeEnv = (env: Environment, history: History) => {
        setCurrentEnv(env)
        history.push("/")
    }

    /*
    const token = localStorage.getItem('token')
    let initIsLoggedIn = false
    if (token) {
        svc.defaults.headers = {
            'Authorization': 'Bearer ' + token
        }
        document.cookie = `token_${hostId}=${token}`
        initIsLoggedIn = true
    }
    */

    useEffect(() => {
        console.log("useEffect []");
        const token = localStorage.getItem('token')
        if (token) {
            /*
            svc.defaults.headers = {
                'Authorization': 'Bearer ' + token
            }
            */
            svc.defaults.headers["Authorization"] = 'Bearer ' + token
            document.cookie = `token_${hostId}=${token}`
            setIsLoggedIn(true);
        }
    }, [])

    useEffect(() => {
        console.log("useEffect [isLoggedIn]");
        (async () => {
            try {
                if (isLoggedIn) {
                    const res = await svc.post('/usergetcurrent');
                    //console.log(res.data)
                    if (res.data.ok) {
                        svc.defaults.headers["REGX_ENV"] = res.data.env.envId;
                        document.cookie = `env_${hostId}=${res.data.env.envId}`;
                        setCurrentUser(res.data.data.userId)
                        setCurrentEnv(res.data.env)
                        i18next.changeLanguage(res.data.data.currentLang)
                    }
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [isLoggedIn])

    const refreshEnvironments = () => {
        (async () => {
            try {
                const res = await svc.post('/environmentlist');
                setEnvList(res.data.data)
            } catch (error) {
                utils.showError(error)
            }
        })()
    }

    useEffect(() => {
        console.log("useEffect [envList]");
        /*
        if ((envList.length > 0) && ("envId" in currentEnv) && (envList.findIndex((e) => e.envId === currentEnv.envId) === -1)) {
            utils.showError("Environment changed")
            setCurrentEnv(envList[0])
        }
        */
        if ((envList.length > 0) && ("envId" in currentEnv)) {
            let idx = envList.findIndex((e) => e.envId === currentEnv.envId)
            if (idx === -1) {
                utils.showError("Environment changed")
                setCurrentEnv(envList[0])
            } else {
                setCurrentEnv(envList[idx])
            }
        }
    }, [envList, currentEnv])

    useEffect(() => {
        console.log("useEffect []");
        /*
        (async () => {
            try {
                {
                    const res = await svc.post('/environmentlist');
                    setEnvList(res.data.data)
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
        */
       refreshEnvironments()
    }, [])

    useEffect(() => {
        console.log("useEffect [currentEnv]");
        (async () => {
            try {
                if ("envId" in currentEnv) {
                    //console.log("ac3x_env=", currentEnv.envId)
                    svc.defaults.headers["REGX_ENV"] = currentEnv.envId;
                    document.cookie = `env_${hostId}=${currentEnv.envId}`;
                    await svc.post('/userupdatecurrent', {currentEnv: currentEnv.envId});
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [currentEnv])

    return (
        <React.Suspense fallback={<Spinner></Spinner>}>
        <div className="App">
            <Router>
                <MenuBar isLoggedIn={isLoggedIn} userId={currentUser} envList={envList} currentEnv={currentEnv} onChangeEnv={handleChangeEnv} onRefreshEnv={refreshEnvironments} />
                <div className="AppBody">
                    <Switch>
                        <Route exact path="/">
                            {isLoggedIn ? <MainMenu /> : <Login isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route exact path="/login">
                            <Login isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />
                        </Route>
                        <Route path="/licenses/:tabId?">
                            {isLoggedIn ? <LicenseMain svc={svc} currentEnv={currentEnv} /> : <Login isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/customers/:tabId?">
                            {isLoggedIn ? <CustomerMain svc={svc} currentEnv={currentEnv} /> : <Login isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/verifications/:tabId?">
                            {isLoggedIn ? <VerifyMain svc={svc} currentEnv={currentEnv} /> : <Login isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/users/:tabId?">
                            {isLoggedIn ? <UserMain svc={svc} /> : <Login isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        {/*
                        <Route path="/numbers/:tabId?">
                            {isLoggedIn ? <NumberMain svc={svc} /> : <Login isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        */}
                        <Route path="/environments/:tabId?">
                            {isLoggedIn ? <EnvironmentMain svc={svc} onUpdate={refreshEnvironments} /> : <Login isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/datamaintenance">
                            {isLoggedIn ? <DataMaintenanceMain svc={svc} /> : <Login isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                        <Route path="/tasks">
                            {isLoggedIn ? <TaskMain svc={svc} /> : <Login isLoggedIn={isLoggedIn} onLogin={handleLogin} onLogout={handleLogout} />}
                        </Route>
                    </Switch>
                </div>
            </Router>
        </div>
        </React.Suspense>
    );
}

function MainMenu() {
    const { t, i18n } = useTranslation();
    return (
        <>
            <div className="Row">
                <Link to="/licenses">
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgLicense} alt="Licenses" />
                        <div>{t('licenses')}</div>
                    </div>
                </Link>
                <Link to="/customers">
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgCustomer} alt="Customers" />
                        <div>{t('customers')}</div>
                    </div>
                </Link>
            </div>

            <div className="Spacing-V-16"></div>
            <div className="Row">
                <Link to="/verifications">
                    <div className="MenuTile">
                        <img className="MenuTileImage" src={svgVerify} alt="Verifications" />
                        <div>{t('verifications')}</div>
                    </div>
                </Link>
            </div>
        </>
    )
}

interface MenuBarProps {
    isLoggedIn?: boolean
    userId?: string
    envList?: Environment[]
    currentEnv?: Environment
    onChangeEnv?: (env: Environment, history: History) => void
    onRefreshEnv?: () => void
}

function MenuBar({ isLoggedIn = false, userId = "", envList = [], currentEnv = {} as Environment, onChangeEnv = () => {}, onRefreshEnv = () => {} }: MenuBarProps) {
    const { t, i18n } = useTranslation();
    const history = useHistory()

    const imgLogo = <img className="AppLogo" src={svgLogo} alt="Logo" />
    const imgLicense = <img className="MenuIcon" src={svgLicense} alt="Account" />
    const imgDatabase = <img className="MenuIcon" src={svgDatabase} alt="Database" />
    const imgTransaction = <img className="MenuIcon" src={svgTransaction} alt="Transaction" />
    const imgTools = <img className="MenuIcon" src={svgTools} alt="Tools" />
    const imgSort = <img className="MenuIcon" src={svgSort} alt="Sort" />
    const imgNumber = <img className="MenuIcon" src={svgNumber} alt="Number" />
    const imgReport = <img className="MenuIcon" src={svgReport} alt="Report" />
    const imgPaper = <img className="MenuIcon" src={svgPaper} alt="Report" />
    const imgUser = <img className="MenuIcon" src={svgUser} alt="Login" />
    const imgCustomer = <img className="MenuIcon" src={svgCustomer} alt="Customer" />
    const imgVerify = <img className="MenuIcon" src={svgVerify} alt="Verify" />
    const imgTask = <img className="MenuIcon" src={svgTask} alt="Task" />
    const imgEnvironment = <img className="MenuIcon" src={svgFolder} alt="Environment" />

    const menuDatabase =
        <Popover minimal position={Position.BOTTOM_LEFT} content={
            <Menu>
                <MenuItem icon={imgLicense} text={t('licenses')} href="#/licenses" />
                <MenuItem icon={imgCustomer} text={t('customers')} href="#/customers" />
                <Menu.Divider />
                <MenuItem icon={imgUser} text={t('users')} href="#/users" />
                <MenuItem icon={imgEnvironment} text={t('environments')} href="#/environments" />
            </Menu>
        }>
            <Button minimal icon={imgDatabase} text={t('database')} />
        </Popover>
    const menuTransaction =
        <Popover minimal position={Position.BOTTOM_LEFT} content={
            <Menu>
                <MenuItem icon={imgVerify} text={t('verifications')} href="#/verifications" />
            </Menu>
        }>
            <Button minimal icon={imgTransaction} text={t('transaction')} />
        </Popover>
    const menuTools =
    <Popover minimal position={Position.BOTTOM_LEFT} content={
        <Menu>
            <MenuItem icon={imgSort} text={t('data_file_maintenance')} href="#/datamaintenance" />
            {/*<MenuItem icon={imgNumber} text={t('numbers')} href="#/numbers" />*/}
        </Menu>
    }>
        <Button minimal icon={imgTools} text={t('tools')} />
    </Popover>
    const menuReport =
    <Popover minimal position={Position.BOTTOM_LEFT} content={
        <Menu>
        </Menu>
    }>
        <Button minimal icon={imgReport} text={t('report')} />
    </Popover>

    const changeUserLanguage = (lng: string) => {
        (async () => {
            try {
                if (isLoggedIn) {
                    await svc.post('/userupdatecurrent', {currentLang: lng});
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
    }

    let lngList = [
        {lngName: "English", lng: "en"},
        {lngName: "繁體中文", lng: "zh-HK"},
        {lngName: "简体中文", lng: "zh-CN"},
    ]

    return (
        <>
            <Navbar className="No-Print">
                <Navbar.Group align={Alignment.LEFT}>
                    <Navbar.Heading><AnchorButton minimal icon={imgLogo} text="REGx" href="#/" onClick={onRefreshEnv} /></Navbar.Heading>
                    <Navbar.Divider />
                    {isLoggedIn ? menuDatabase : null}
                    {isLoggedIn ? menuTransaction : null}
                    {isLoggedIn ? menuReport : null}
                    {isLoggedIn ? menuTools : null}
                </Navbar.Group>
                <Navbar.Group align={Alignment.RIGHT}>
                    {isLoggedIn && <div className="No-SmallScreen">{currentEnv.companyName}</div>}
                    {isLoggedIn && <Navbar.Divider />}
                    {isLoggedIn && <Popover minimal position={Position.BOTTOM_LEFT} content={
                        <Menu>
                            {envList.map((env) => <MenuItem key={env.envId} icon={imgEnvironment} text={env.envName} onClick={() => {onChangeEnv(env, history)}} />)}
                        </Menu>
                    }>
                        <Button className="MenuEnv" minimal icon={imgEnvironment} text={currentEnv.envName} />
                    </Popover>}
                    {isLoggedIn && <Navbar.Divider />}
                    {isLoggedIn && <Link to="/tasks"><Button minimal icon={imgTask} /></Link>}
                    {isLoggedIn && <Link to="/login"><Button minimal icon={imgUser} text={userId} /></Link>}
                    <Popover minimal position={Position.BOTTOM_LEFT} content={
                        <Menu>
                            {lngList.map((lng) => <MenuItem key={lng.lng} icon="translate" text={lng.lngName} onClick={() => {i18n.changeLanguage(lng.lng); changeUserLanguage(lng.lng)}} />)}
                        </Menu>
                    }>
                        <Button className="MenuEnv" minimal icon="translate" text="Language" />
                    </Popover>
                </Navbar.Group>
            </Navbar>
            <div className="No-Print Spacing-V-16"></div>
        </>
    )
}

export default App;
