import { Position, Toaster, Intent } from "@blueprintjs/core";

const toaster = Toaster.create({
    position: Position.TOP,
})

export const showError = (err: any) => {
    if (err.response) {
        toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
    } else if (err.request) {
        toaster.show({ message: "Request failed", intent: Intent.WARNING })
    } else if (err.message) {
        toaster.show({ message: err.message, intent: Intent.WARNING })
    } else {
        toaster.show({ message: err, intent: Intent.WARNING })
    }
}

export const showSuccess = (message: string) => {
    toaster.show({ message: message, intent: Intent.SUCCESS })
}

export function handleStringChange(handler: (value: string) => void) {
    return (event: React.FormEvent<HTMLElement>) => handler((event.target as HTMLInputElement).value);
}

export function handleBooleanChange(handler: (checked: boolean) => void) {
    return (event: React.FormEvent<HTMLElement>) => handler((event.target as HTMLInputElement).checked);
}

export function handleNumberChange(handler: (value: number) => void) {
    return handleStringChange(value => handler(+value));
}

export function drValue(n: any): string {
    if (typeof n === "number" && n > 0)
        return n.toLocaleString(undefined, { minimumFractionDigits: 2 });
    return ""
}

export function crValue(n: any): string {
    if (typeof n === "number" && n < 0)
        return (-n).toLocaleString(undefined, { minimumFractionDigits: 2 });
    return ""
}

export function formatMoney(n: any, d:number = 2): string {
    //if (typeof n === "number")
        return roundMoney(Number(n), d).toLocaleString(undefined, { minimumFractionDigits: d });
    //return ""
}

export function formatNumber(n: any): string {
    if (typeof n === "number")
        return n.toLocaleString();
    return ""
}

export function formatDate(date: Date): string {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

export function formatDateMin(date1: Date, date2: Date): string {
    var date = date1
    if (date2 && date1 > date2) {
        date = date2
    }
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

export function roundMoney(n: any, d:number = 2): number {
    if (typeof n === "number")
        return Number.parseFloat(n.toFixed(d));
    return 0
}

export function fileSize(n: number): string {
    if (n >= 1024**3) {
        return (n / (1024**3)).toFixed(1)+" TB"
    }
    if (n >= 1024**2) {
        return (n / (1024**2)).toFixed(1)+" MB"
    }
    if (n >= 1024) {
        return (n / (1024)).toFixed(1)+" KB"
    }
    return n.toLocaleString()
}

export function fileBase(path: string): string {
    return path.replace(/^.*\\/, "");
}

//           1
// 01234567890123456
// yyyymmddhhmmssfff
export function hb_StoD(s: string): Date {
    let dateString = s.slice(0,4)+'-'+s.slice(4,6)+'-'+s.slice(6,8)+'T'+s.slice(8,10)+':'+s.slice(10,12)+':'+s.slice(12,14)+'.'+s.slice(14,17)
    return new Date(dateString)
}

export function formatTime(d: Date): string {
    return d.toLocaleTimeString([], {hour12: false})
}

export function statusName(status: string): string {
    let statusName = status

    switch (status) {
        case '':
            statusName = 'unregistered'
            break;
        case 'R':
            statusName = 'registered'
            break;
        case 'L':
            statusName = 'locked'
            break;
    }
    return statusName
}

export function numTypeName(numType: string): string {
    let numTypeName = numType

    switch (numType) {
        case 'J':
            numTypeName = 'journal'
            break;
    }
    return numTypeName
}

export function formatLicenseId(id: string, mask: boolean): string {
    if (mask)
        return id.length === 16 ? id.slice(0, 4)+"-XXXX-XXXX-XXXX" : ""
    else
        return id.length === 16 ? id.slice(0, 4)+"-"+id.slice(4, 8)+"-"+id.slice(8, 12)+"-"+id.slice(12, 16) : ""
}