import React, { useState, FormEvent, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { FormGroup, InputGroup, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, Classes, Alert } from "@blueprintjs/core";

import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next';

import { User } from './Types'

interface UserViewProps {
    user: User,
    baseUrl?: string,
    token?: string | null,
    onEdit?: () => void,
    onNew?: () => void,
    onDelete?: (user: User) => void,
}

function UserView({ user = {} as User, baseUrl = "", token = "", onEdit = () => { }, onNew = () => { }, onDelete = () => {} }: UserViewProps) {
    const { t, i18n } = useTranslation();
    const [isOpenDelete, setIsOpenDelete] = useState(false)

    if (!("userId" in user)) return null

    return (
        <div>
            <Alert icon="trash" intent="danger" confirmButtonText={t('button_delete')} isOpen={isOpenDelete} onConfirm={() => {onDelete(user); setIsOpenDelete(false)}} onCancel={() => setIsOpenDelete(false)} cancelButtonText={t('button_no')} canEscapeKeyCancel={true} canOutsideClickCancel={true}>
                <p>{t('confirm_delete_user', {user})}</p>
            </Alert>
            <div className="Row">
                <Button className="Col-1-1" icon="add" onClick={() => onNew()}>{t('new')}</Button>
                <Button className="Col-2-1" icon="edit" onClick={() => onEdit()}>{t('edit')}</Button>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-1" label={t('user_id')}>
                    <InputGroup readOnly value={user.userId} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label={t('user_name')}>
                    <InputGroup readOnly value={user.userName} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label={t('password')}>
                    <InputGroup readOnly type="password" value="********" />
                </FormGroup>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <Button className="Col-1-1" icon="trash" onClick={() => setIsOpenDelete(!isOpenDelete)}>{t('delete')}</Button>
            </div>
        </div>
    )
}

interface UserEditProps {
    user?: User,
    isNew?: boolean,
    onSave?: (user: User) => void,
    onCancel?: () => void;
}

// onChange={utils.handleStringChange(s => setAc(prevState => Object.assign(prevState, {acName1: s})))}
function UserEdit({ user = {} as User, isNew = false, onSave = () => { }, onCancel = () => { } }: UserEditProps) {
    const { t, i18n } = useTranslation();
    const { register, handleSubmit, reset, setValue } = useForm<User>()

    useEffect(() => {
        // Call reset to set default values if user is passed
        if ("userId" in user) {
            reset(user)
        }
    }, [user, reset])

    if (!isNew && !("userId" in user)) return null

    const onSubmit = (data: User) => {
        //console.log("onsubmit called", data)
        onSave(data)
    }

        const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>{t('cancel')}</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>{t('save')}</Button>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-1" label={t('user_id')} labelFor="inputUserId">
                    <InputGroup readOnly={!isNew} id="inputUserId" name="userId" inputRef={register} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label={t('user_name')} labelFor="inputUserName">
                    <InputGroup id="inputUserName" name="userName" inputRef={register} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label={t('password')} labelFor="inputPassword">
                    <InputGroup type="password" id="inputPassword" name="password" inputRef={register} />
                </FormGroup>
            </div>
        </form>
    )
}

interface UserMainProps {
    svc: AxiosInstance
}

function UserMain({ svc }: UserMainProps) {
    let { tabId: defaultTabId = "list" }: { tabId: string } = useParams()
    const { t, i18n } = useTranslation();
    const history = useHistory()

    const [data, setData] = useState([] as User[])

    const [searchKey, setSearchKey] = useState("userId")
    const [searchValue, setSearchValue] = useState("")

    const [showFilters, setShowFilters] = useState(true)

    const [searchName, setSearchName] = useState("")
    const [searchType, setSearchType] = useState("")

    const [searchLimit, setSearchLimit] = useState(0)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState(defaultTabId) //useState("list")
    const [currentUser, setCurrentUser] = useState({} as User)

    const [currentMode, setCurrentMode] = useState("show")

    useEffect(() => {
        setSelectedTabId(defaultTabId)
    }, [defaultTabId])

    const changeTab = (tabId: string) => {
        history.push(`/users/${tabId}`)
        setSelectedTabId(tabId)
    }

    /*
    const onTest = async () => {
        try {
            const res = await svc.post('/info');
            console.log(res.data)
        } catch (error) {
            showError(error)
        }
    }
    */

    useEffect(() => {
        loadAllUsers()
    }, [])

    useEffect(() => {
        if (data.length > 0) {
            let i = 0;
            if ("userId" in currentUser) {
                i = data.findIndex(u => u.userId === currentUser.userId)
            }
            setCurrentUser(i > 0 ? data[i] : data[0])
        }
    }, [data])

    const loadAllUsers = async () => {
        try {
            const res = await svc.post('/userlist');

            setData(res.data.data)
            if (res.data.data.length > 0) {
                setCurrentUser(res.data.data[0])
            } else {
                utils.showError(t('no_record'))
            }
            //changeTab("list")
        } catch (error) {
            utils.showError(error)
        }
    }

    const refreshUsers = async () => {
        try {
            const res = await svc.post('/userlist',
                {
                    by: searchKey,
                    start: searchValue,
                    limit: searchLimit,
                    backward: searchBackward,
                    filters: {
                        userName: searchName,
                        userType: searchType,
                    }
                });

            setData(res.data.data)
            if (res.data.data.length === 0) {
                utils.showError(t('no_record'))
            }
            /*
            if (res.data.data.length > 0) {
                setCurrentUser(res.data.data[0])
            } else {
                utils.showError(t('no_record'))
            }
            */
            //changeTab("list")
        } catch (error) {
            utils.showError(error)
        }
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()
        refreshUsers()
    }

    const editOnSave = async (user: User) => {
        try {
            const res = await svc.post('/userupdate', user)
            utils.showSuccess(t('saved'))
            for (let i = 0; i < data.length; i++) {
                if (data[i].userId === res.data.data.userId) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    //setCurrentUser(res.data.data)
                    break
                }
            }
            setCurrentUser(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const newOnSave = async (user: User) => {
        try {
            const res = await svc.post('/useradd', user)
            utils.showSuccess(t('saved'))
            refreshUsers()
            /*
            for (let i = 0; i < data.length; i++) {
                if (data[i].userId === res.data.data.userId) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            */
            setCurrentUser(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const viewOnDelete = async (user: User) => {
        try {
            //console.log("deleting user:", vch)
            const res = await svc.post('/userdelete', user)
            utils.showSuccess(t('deleted'))
            setData(data.filter(user => !(user.userId === res.data.userId)))
            setCurrentUser({} as User)
            changeTab("list")
        } catch (error) {
            utils.showError(error)
        }
    }

    // currentMode = show
    let detail = <UserView user={currentUser} baseUrl={svc.defaults.baseURL} token={localStorage.getItem('token')} onEdit={() => setCurrentMode("edit")} onNew={() => setCurrentMode("new")} onDelete={viewOnDelete}></UserView>
    switch (currentMode) {
        case "edit":
            detail = <UserEdit user={currentUser} onSave={editOnSave} onCancel={() => setCurrentMode("show")}></UserEdit>
            break
        case "new":
            detail = <UserEdit isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); changeTab("list") }}></UserEdit>
            break
    }

    let list = <>
        <div className="Row">
            <Button className="Col-1-1" icon="add" onClick={() => { setCurrentMode("new"); changeTab("detail") }}>{t('new')}</Button>
        </div>
        <div className="Spacing-V-16" />
        <HTMLTable striped interactive condensed>
            <thead>
                <tr>
                    <th className="TCol-ACCode">{t('user_id')}</th>
                    <th className="TCol-ACName">{t('user_name')}</th>
                </tr>
            </thead>
            <tbody>
                {data.map((user, i) =>
                    <tr key={i} onDoubleClick={() => { changeTab("detail") }}>
                        <td className="TCol-ACCode" onClick={() => { setCurrentUser(user) }}>{user.userId}</td>
                        <td className="TCol-ACName" onClick={() => { setCurrentUser(user) }}>{user.userName}</td>
                    </tr>
                )}
            </tbody>
        </HTMLTable>
    </>

    return (
        <>
            <div style={{fontSize: "21px", fontWeight: 700}}>{t('users')}</div>
            <div className="Spacing-V-16"></div>
            <form onSubmit={onSubmit}>
                <div className="Row">
                    <FormGroup className="Col-1-3" label={t('search')} labelFor="inputSearchValue">
                        <ControlGroup fill>
                            <HTMLSelect className={Classes.FIXED} value={searchKey} onChange={utils.handleStringChange(s => setSearchKey(s))}>
                                <option value="userId">{t('user_id')}</option>
                                <option value="_recordNo">Record No.</option>
                            </HTMLSelect>
                            <InputGroup
                                id="inputSearchValue"
                                placeholder={t('search')}
                                value={searchValue}
                                onChange={utils.handleStringChange(s => setSearchValue(s))}
                                leftIcon="search"
                                rightElement={<Button icon={IconNames.ARROW_RIGHT} minimal type="submit"></Button>}
                            />
                        </ControlGroup>
                    </FormGroup>
                    {/*<Checkbox className="Col-4-1 Checkbox-LabeledFormGroup" label="Backward" onChange={utils.handleBooleanChange(v => setSearchBackward(v))} />*/}
                    {/*<Button className="Col-5-1 Button-LabeledFormGroup" icon="filter" onClick={() => { setShowFilters(!showFilters) }} >Filters</Button>*/}
                    {/*<Button className="Col-5-1 Button-LabeledFormGroup" icon={IconNames.LAB_TEST} onClick={() => { onTest() }} >Test</Button>*/}
                </div>
                <Collapse isOpen={showFilters}>
                    <div className="Row">
                        <FormGroup className="Col-1-3" label={t('user_name')} labelFor="searchIdNo">
                            <InputGroup id="searchName" value={searchName} onChange={utils.handleStringChange(s => setSearchName(s))} />
                        </FormGroup>
                        <div className="Col-4-2 Col-H">
                            {/*
                            <FormGroup label="Type" labelFor="searchType">
                                <InputGroup className="Input-XS" id="searchType" value={searchType} onChange={utils.handleStringChange(s => setSearchType(s))} />
                            </FormGroup>
                            */}
                            {/*
                            <FormGroup label="Limit" labelFor="searchLimit" >
                                <InputGroup className="Input-XS" id="searchLimit" value={searchLimit.toString()} onChange={utils.handleNumberChange(n => setSearchLimit(n))} />
                            </FormGroup>
                            */}
                        </div>
                    </div>
                </Collapse>
            </form>
            <Tabs onChange={(newTabId) => changeTab(newTabId as string)} selectedTabId={selectedTabId}>
                <Tab id="list" title={t('list')} panel={list} />
                <Tab id="detail" title={t('detail')} panel={detail} />
            </Tabs>
        </>
    );
}

export default UserMain