import React, { useState, FormEvent } from 'react';
import { FormGroup, InputGroup, Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { useHistory } from 'react-router-dom';
import { History } from 'history'
import { useTranslation } from 'react-i18next';

import * as utils from "./utils"

import "./Login.scss"

import imgLogin from "./svg/login.svg"

interface LoginProps {
    isLoggedIn?: boolean
    onLogin?: (credential: LoginCredential, history: History) => void,
    onLogout?: () => void
}

export interface LoginCredential {
    login: string,
    password: string
}

function Login({ isLoggedIn = false, onLogin = () => { }, onLogout = () => { } }: LoginProps) {
    const { t, i18n } = useTranslation();
    const [login, setLogin] = useState("")
    const [password, setPassword] = useState("")
    const history = useHistory()

    if (isLoggedIn) {
        return (
            <div className="Login">
                <img className="LoginImage" src={imgLogin} alt="Login" />
                <div className="Spacing-V-16"></div>
                <Button icon={IconNames.LOG_OUT} onClick={() => onLogout()}>{t('logout')}</Button>
            </div>
        )
    } else {
        const handleSubmit = (e: FormEvent) => {
            e.preventDefault()
            onLogin({ login: login, password: password }, history)
        }

        return (
            <form className="Login">
                <img className="LoginImage" src={imgLogin} alt="Login" />
                <div className="Spacing-V-16"></div>
                <FormGroup label={t('login')} labelFor="inputLogin">
                    <InputGroup id="inputLogin" value={login} onChange={utils.handleStringChange(s => setLogin(s))} />
                </FormGroup>
                <FormGroup label={t('password')} labelFor="inputPassword">
                    <InputGroup id="inputPassword" type="password" value={password} onChange={utils.handleStringChange(s => setPassword(s))} />
                </FormGroup>
                <div className="Spacing-V-16"></div>
                <Button type="submit" icon={IconNames.LOG_IN} onClick={handleSubmit}>{t('login')}</Button>
            </form>
        )
    }
}

export default Login