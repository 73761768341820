import React, { useState, useEffect, FormEvent, useCallback } from 'react';
import { HTMLTable, AnchorButton, Button, FileInput, ProgressBar, Collapse, InputGroup, Alert } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { AxiosInstance } from 'axios';
import { useTranslation } from 'react-i18next';

import * as utils from "./utils";

interface DataMaintenanceMainProps {
    svc: AxiosInstance
}

interface BackupInfo {
    backupName: string,
    size: number,
    date: string,
    time: string
}

interface TaskInfo {
    id: string,
    ref: string,
    description: string,
    startTime: string,
    endTime: string,
    userId: string,
    progressValue: number,
    progressMax: number,
    status: number,
    message: string,
    logFile: string
}

let taskOnCompleteMap = new Map()

function DataMaintenanceMain({ svc }: DataMaintenanceMainProps) {
    const { t, i18n } = useTranslation();
    const [backupInfoList, setBackupInfoList] = useState([] as BackupInfo[])
    const [taskList, setTaskList] = useState([] as TaskInfo[])
    const [showTasks, setShowTasks] = useState(false)
    const [showAdvanced, setShowAdvanced] = useState(false)
    const [isOpenClearTx, setIsOpenClearTx] = useState(false)
    const [isOpenClearAll, setIsOpenClearAll] = useState(false)
    const [isOpenRestoreLast, setIsOpenRestoreLast] = useState(false)
    const [backupTo, setBackupTo] = useState("")
    const [backupName, setBackupName] = useState("")
    const [isOpenRestoreBackup, setIsOpenRestoreBackup] = useState(false)
    const [isOpenDeleteBackup, setIsOpenDeleteBackup] = useState(false)
    const [file1Label, setFile1Label] = useState('choose_file')
   
    const refreshBackupList = useCallback(() => {
        (async () => {
            try {
                const res = await svc.post('/backuplist',
                    {});
                    setBackupInfoList(res.data.data)
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [svc])

    useEffect(() => {
        /*
        (async () => {
            try {
                const res = await svc.post('/backuplist',
                    {});
                    setBackupInfoList(res.data.data)
            } catch (error) {
                utils.showError(error)
            }
        })()
        */
       refreshBackupList()
    }, [svc, refreshBackupList])

    useEffect(() => {
        if (taskList.findIndex((t: TaskInfo) => t.status === 0) > -1) {
            //console.log("running task(s) found, refresh tasklist in 1s")
            setTimeout(async () => {
                try {
                    const res = await svc.post('/tasklist', taskList.map((t) => t.id));
                    res.data.data.map((task: TaskInfo) => {
                        if (task.status === 1) {
                            if (taskOnCompleteMap.has(task.id)) {
                                taskOnCompleteMap.get(task.id)()
                                taskOnCompleteMap.delete(task.id)    
                            }
                        }
                    })
                    setTaskList(res.data.data)
                } catch (error) {
                    utils.showError(error)
                }
            }, 1000)
        } else {
            //console.log("all task(s) completed.")
        }
    }, [taskList, svc])

    const onRebuildIndexes = () => {
        (async () => {
            try {
                const res = await svc.post('/rebuildindexes');
                setTaskList(res.data.data)
                setShowTasks(true)
            } catch (error) {
                utils.showError(error)
            }
        })()
    }

    const onBackupDataFiles = () => {
        (async () => {
            try {
                const res = await svc.post('/backupdatafiles', {backupName: backupTo});
                res.data.data.map((task: TaskInfo) => taskOnCompleteMap.set(task.id, () => refreshBackupList()))
                setTaskList(res.data.data)
                setShowTasks(true)
            } catch (error) {
                utils.showError(error)
            }
        })()
    }

    const onRestoreLastBackup = () => {
        (async () => {
            try {
                const res = await svc.post('/restorelastbackup');
                setTaskList(res.data.data)
                setShowTasks(true)
            } catch (error) {
                utils.showError(error)
            }
        })()
    }

    const onRestoreBackup = () => {
        (async () => {
            try {
                const res = await svc.post('/restorebackup', {backupName: backupName});
                setTaskList(res.data.data)
                setShowTasks(true)
            } catch (error) {
                utils.showError(error)
            }
        })()
    }

    const onDeleteBackup = () => {
        (async () => {
            try {
                const res = await svc.post('/backupdelete', {backupName: backupName});
                if (res.data.ok) {
                    utils.showSuccess("Deleted")
                    refreshBackupList()
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
    }

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();

        (async () => {
            try {
                const res = await svc.post('/backupupload',
                    new FormData(e.target as HTMLFormElement), {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                utils.showSuccess(`${res.data.uploaded} file(s) uploaded`)
                setBackupInfoList(res.data.data)
            } catch (error) {
                utils.showError(error)
            }
        })()
    }

    const onClearTx = () => {
        (async () => {
            try {
                const res = await svc.post('/cleardata', {type: "tx"});
                if (res.data.ok) {
                    utils.showSuccess("All transactions cleared")
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
    }

    const onClearAll = () => {
        (async () => {
            try {
                const res = await svc.post('/cleardata', {type: "all"});
                if (res.data.ok) {
                    utils.showSuccess("All data cleared")
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
    }

    return (
        <>
            <div style={{fontSize: "21px", fontWeight: 700}}>{t('data_file_maintenance')}</div>
            <div className="Spacing-V-16"></div>
            <div className="Row">
                <Button className="Col-1-1" icon={IconNames.SORT_ALPHABETICAL} onClick={onRebuildIndexes}>{t('rebuild_indexes')}</Button>
                <div className="Col-2-1">
                    <InputGroup id="backupTo" value={backupTo} placeholder={t('backup_name')} onChange={utils.handleStringChange(s => setBackupTo(s))} />
                </div>
                <Button className="Col-3-1" icon={IconNames.ARCHIVE} onClick={onBackupDataFiles}>{t('backup_now')}</Button>
                <Button className="Col-4-1" icon={IconNames.MORE} onClick={() => setShowAdvanced(!showAdvanced)}>{t('advanced')}</Button>
                <Button className="Col-5-1" icon={IconNames.LIST} onClick={() => setShowTasks(!showTasks)}>{t('tasks')}</Button>
            </div>
            <div className="Spacing-V-16"></div>
            <Collapse isOpen={showTasks}>
                <p>{t('tasks')}</p>
                <HTMLTable striped interactive condensed>
                    <thead>
                        <th className="TCol-Ref">{t('task_name')}</th>
                        <th className="TCol-Progress">{t('progress')}</th>
                        <th className="TCol-Message">{t('message')}</th>
                        <th className="TCol-Log">{t('log')}</th>
                    </thead>
                    <tbody>
                        {taskList.map((t, i) =>
                            <tr key={i}>
                                <td className="TCol-Ref">{t.ref}</td>
                                <td className="TCol-Progress"><ProgressBar stripes={false} animate={false} value={t.progressValue / t.progressMax} /></td>
                                <td className="TCol-Message">{t.message}</td>
                                <td className="TCol-Log">{t.logFile && <a key={i} target="_blank" rel="noopener noreferrer" href={`${svc.defaults.baseURL}/logdownload?filename=${t.logFile}&inline`}>View</a>}</td>
                            </tr>
                        )}
                    </tbody>
                </HTMLTable>
                <div className="Spacing-V-16"></div>
            </Collapse>
            <Collapse isOpen={showAdvanced}>
                <p>{t('restore_data')}</p>
                <div className="Row">
                        <Button className="Col-1-2" icon={IconNames.UNARCHIVE} intent="danger" onClick={() => setIsOpenRestoreLast(true)}>{t('restore_last_backup')}</Button>
                        <Button className="Col-5-1" icon={IconNames.REFRESH} onClick={() => refreshBackupList()}>{t('refresh')}</Button>
                </div>
                <div className="Spacing-V-16"></div>
                <HTMLTable striped interactive condensed>
                    <thead>
                        <tr>
                            <th className="TCol-FileName">{t('backup_name')}</th>
                            <th className="TCol-DateTime">{t('time')}</th>
                            <th className="TCol-Records"><div className="Align-R">{t('size')}</div></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {backupInfoList.map((f, i) =>
                            <tr key={i}>
                                <td className="TCol-FileName">{f.backupName}</td>
                                <td className="TCol-DateTime">{f.date+' '+f.time}</td>
                                <td className="TCol-Records"><div className="Align-R">{utils.fileSize(f.size)}</div></td>
                                <td><AnchorButton icon={IconNames.DOWNLOAD} href={`${svc.defaults.baseURL}/backupdownload?backupName=${f.backupName}`}>{t('download')}</AnchorButton></td>
                                <td><Button icon={IconNames.UNARCHIVE}  onClick={() => {setBackupName(f.backupName); setIsOpenRestoreBackup(true)}}>{t('restore')}</Button></td>
                                <td><Button icon={IconNames.TRASH}  onClick={() => {setBackupName(f.backupName); setIsOpenDeleteBackup(true)}}>{t('delete')}</Button></td>
                            </tr>
                        )}
                    </tbody>
                </HTMLTable>
                <div className="Spacing-V-8"></div>
                <p>{t('upload_backup')}</p>
                <form className="Import" onSubmit={onSubmit}>
                    <div className="Row">
                        <FileInput inputProps={{ name: "file1" }} buttonText={t('browse')} text={t(file1Label)} onInputChange={utils.handleStringChange((s) => setFile1Label(utils.fileBase(s)))} />
                    </div>
                    <div className="Spacing-V-8"></div>
                    <div className="Row">
                        <Button className="Col-1-1" type="submit" icon={IconNames.UPLOAD}>{t('upload')}</Button>
                    </div>
                </form>
                <div className="Spacing-V-8"></div>
                <p>{t('clear_data')}</p>
                <div className="Row">
                        <Button className="Col-1-2" icon={IconNames.TRASH} intent="danger" onClick={() => setIsOpenClearTx(true)}>{t('clear_tx')}</Button>
                </div>
                <div className="Spacing-V-16"></div>
                <div className="Row">
                        <Button className="Col-1-2" icon={IconNames.TRASH} intent="danger" onClick={() => setIsOpenClearAll(true)}>{t('clear_all_data')}</Button>
                </div>
                <div className="Spacing-V-16"></div>
            </Collapse>
            <Alert icon="trash" intent="danger" confirmButtonText={t('button_clear')} isOpen={isOpenClearTx} onConfirm={() => {onClearTx(); setIsOpenClearTx(false)}} onCancel={() => setIsOpenClearTx(false)} cancelButtonText={t('button_no')} canEscapeKeyCancel={true} canOutsideClickCancel={true}>
                <p>{t('confirm_clear_tx')}</p>
            </Alert>
            <Alert icon="trash" intent="danger" confirmButtonText={t('button_clear_all')} isOpen={isOpenClearAll} onConfirm={() => {onClearAll(); setIsOpenClearAll(false)}} onCancel={() => setIsOpenClearAll(false)} cancelButtonText={t('button_no')} canEscapeKeyCancel={true} canOutsideClickCancel={true}>
                <p>{t('confirm_clear_all_data')}</p>
            </Alert>
            <Alert icon="unarchive" intent="danger" confirmButtonText={t('button_restore')} isOpen={isOpenRestoreLast} onConfirm={() => {onRestoreLastBackup(); setIsOpenRestoreLast(false)}} onCancel={() => setIsOpenRestoreLast(false)} cancelButtonText={t('button_no')} canEscapeKeyCancel={true} canOutsideClickCancel={true}>
                <p>{t('confirm_restore_last_backup')}</p>
            </Alert>
            <Alert icon="unarchive" intent="danger" confirmButtonText={t('button_restore')} isOpen={isOpenRestoreBackup} onConfirm={() => {onRestoreBackup(); setIsOpenRestoreBackup(false)}} onCancel={() => setIsOpenRestoreBackup(false)} cancelButtonText={t('button_no')} canEscapeKeyCancel={true} canOutsideClickCancel={true}>
                <p>{t('confirm_restore_from', {backup: backupName})}</p>
            </Alert>
            <Alert icon="trash" intent="danger" confirmButtonText={t('button_delete')} isOpen={isOpenDeleteBackup} onConfirm={() => {onDeleteBackup(); setIsOpenDeleteBackup(false)}} onCancel={() => setIsOpenDeleteBackup(false)} cancelButtonText={t('button_no')} canEscapeKeyCancel={true} canOutsideClickCancel={true}>
                <p>{t('confirm_delete_backup', {backup: backupName})}</p>
            </Alert>
        </>
    )
}

export default DataMaintenanceMain