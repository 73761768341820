import React, { useState, FormEvent, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, Button, Tabs, Tab, Checkbox, Collapse, HTMLSelect, HTMLTable, ControlGroup, Classes, MenuItem, Alert } from "@blueprintjs/core";

import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next';

import { License, Environment } from './Types'

interface LicenseViewProps {
    lcn: License,
    baseUrl?: string,
    token?: string | null,
    onEdit?: () => void,
    onNew?: () => void,
    onDelete?: (lcn: License) => void,
    onUnregister?: (lcn: License) => void,
}

function LicenseView({ lcn = {} as License, baseUrl = "", token = "", onEdit = () => { }, onNew = () => { }, onDelete = () => {}, onUnregister = () => {} }: LicenseViewProps) {
    const { t, i18n } = useTranslation();
    const [isOpenDelete, setIsOpenDelete] = useState(false)
    const [isOpenUnregister, setIsOpenUnregister] = useState(false)

    useEffect(() => {
        console.log("useEffect [lcn]", lcn)
    }, [lcn])

    if (!("licenseId" in lcn)) return null

    return (
        <div>
            <Alert icon="trash" intent="danger" confirmButtonText={t('button_delete')} isOpen={isOpenDelete} onConfirm={() => {onDelete(lcn); setIsOpenDelete(false)}} onCancel={() => setIsOpenDelete(false)} cancelButtonText={t('button_no')} canEscapeKeyCancel={true} canOutsideClickCancel={true}>
                <p>{t('confirm_delete_license', {lcn})}</p>
            </Alert>
            <Alert icon="trash" intent="danger" confirmButtonText={t('button_unregister')} isOpen={isOpenUnregister} onConfirm={() => {onUnregister(lcn); setIsOpenUnregister(false)}} onCancel={() => setIsOpenUnregister(false)} cancelButtonText={t('button_no')} canEscapeKeyCancel={true} canOutsideClickCancel={true}>
                <p>{t('confirm_unregister_license', {lcn})}</p>
            </Alert>
            <div className="Row">
                <Button className="Col-1-1" icon="add" onClick={() => onNew()}>{t('new')}</Button>
                <Button className="Col-2-1" icon="edit" onClick={() => onEdit()}>{t('edit')}</Button>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-2" label={t('license_id')}>
                    <InputGroup readOnly value={lcn.licenseId} />
                </FormGroup>
                <FormGroup className="Col-3-1" label={t('issue_date')}>
                    <InputGroup readOnly value={lcn.issueDate} />
                </FormGroup>
                <FormGroup className="Col-4-1" label={t('type')}>
                    <InputGroup readOnly value={t(lcn.type)} />
                </FormGroup>
                <FormGroup className="Col-5-1" label={t('print')}>
                    <InputGroup readOnly value={lcn.print} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label={t('app')}>
                    <InputGroup readOnly value={lcn.app} />
                </FormGroup>
                <FormGroup className="Col-2-1" label={t('version')}>
                    <InputGroup readOnly value={t(lcn.version)} />
                </FormGroup>
                <FormGroup className="Col-3-1" label={t('edition')}>
                    <InputGroup readOnly value={t(lcn.edition)} />
                </FormGroup>
            </div>
            {/*<div className="Row">
                <FormGroup className="Col-1-1" label={t('region')}>
                    <InputGroup readOnly value={t(lcn.region)} />
                </FormGroup>
                <FormGroup className="Col-2-1" label={t('channel')}>
                    <InputGroup readOnly value={t(lcn.channel)} />
                </FormGroup>
            </div>*/}
            <div className="Row">
                <FormGroup className="Col-1-1" label={t('channel')}>
                    <InputGroup readOnly value={t(lcn.channel)} />
                </FormGroup>
                <FormGroup className="Col-2-1" label={t('partner')}>
                    <InputGroup readOnly value={lcn.partner} />
                </FormGroup>
                <FormGroup className="Col-3-1" label={t('reference')}>
                    <InputGroup readOnly value={lcn.reference} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label={t('remark')}>
                    <InputGroup readOnly value={lcn.remark} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label={t('status')}>
                    <InputGroup readOnly value={t(utils.statusName(lcn.status))} />
                </FormGroup>
                <FormGroup className="Col-2-1" label={t('registration_date')}>
                    <InputGroup readOnly value={lcn.registrationDate} />
                </FormGroup>
                <FormGroup className="Col-3-2" label={t('email')}>
                    <InputGroup readOnly value={lcn.email} />
                </FormGroup>
            </div>
            {lcn.machineIds.length > 0 && <>
            <HTMLTable striped interactive condensed>
                <thead>
                    <tr>
                        <th className="TCol-HardwareID">{t('hardware_id')}</th>
                        <th className="TCol-Date">{t('date')}</th>
                        <th className="TCol-Time">{t('time')}</th>
                    </tr>
                </thead>
                <tbody>
                    {lcn.machineIds.map((hw, i) =>
                        <tr key={i}>
                            <td className="TCol-HardwareID">{hw.id}</td>
                            <td className="TCol-Date">{hw.date}</td>
                            <td className="TCol-Time">{hw.time}</td>
                        </tr>
                    )}
                </tbody>
            </HTMLTable>
            </>}
            <div className="Spacing-V-16" />
            <div className="Row">
                <Button className="Col-1-1" icon="trash" onClick={() => setIsOpenDelete(!isOpenDelete)}>{t('delete')}</Button>
                <Button className="Col-2-1" icon="trash" onClick={() => setIsOpenUnregister(!isOpenUnregister)}>{t('unregister')}</Button>
            </div>
        </div>
    )
}

interface LicenseEditProps {
    lcn?: License,
    isNew?: boolean,
    onSave?: (lcn: License) => void,
    onCancel?: () => void;
}

// onChange={utils.handleStringChange(s => setAc(prevState => Object.assign(prevState, {acName1: s})))}
function LicenseEdit({ lcn = {} as License, isNew = false, onSave = () => { }, onCancel = () => { } }: LicenseEditProps) {
    const { t, i18n } = useTranslation();
    const { register, handleSubmit, reset, setValue } = useForm<License>()

    const [copies, setCopies] = useState(1)

    useEffect(() => {
        // Call reset to set default values if lcn is passed
        if ("licenseId" in lcn) {
            if (isNew) {
                reset({...lcn, ...{issueDate: utils.formatDate(new Date())}})
            } else {
                reset(lcn)
            }
        }
        if (isNew) {
            setValue("issueDate", utils.formatDate(new Date()))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    //}, [lcn, reset])

    useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it manually and call setValue when item is selected
        register({ name: "app" })
        register({ name: "version" })
        register({ name: "edition" })
        register({ name: "region" })
        register({ name: "type" })
        register({ name: "channel" })
        register({ name: "status" })
    }, [register])

    if (!isNew && !("licenseId" in lcn)) return null

    const onSubmit = (data: License) => {
        //console.log("onsubmit called", data)
        onSave({...data, ...{copies: copies}})
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>{t('cancel')}</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>{t('save')}</Button>
                {/*<Button className="Col-2-1" icon="undo" onClick={() => reset(lcn)}>Reset</Button>*/}
                {/*<Button className="Col-3-1" icon="tick" type="submit">Save</Button>*/}
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                {isNew && <FormGroup className="Col-1-2" label={t('license_id')} labelFor="inputLicenseId">
                    <InputGroup readOnly={false} placeholder={t('auto_generated')} id="inputLicenseId" name="licenseId" inputRef={register} />
                </FormGroup>}
                {!isNew && <FormGroup className="Col-1-2" label={t('license_id')} labelFor="inputLicenseId">
                    <InputGroup readOnly={!isNew} id="inputLicenseId" name="licenseId" inputRef={register} />
                </FormGroup>}
                <FormGroup className="Col-3-1" label={t('issue_date')} labelFor="inputIssueDate">
                    <InputGroup type="date" id="inputIssueDate" name="issueDate" inputRef={register} />
                </FormGroup>
                <FormGroup className="Col-4-1" label={t('type')} labelFor="inputType">
                    {/*<InputGroup id="inputType" name="type" inputRef={register} />*/}
                    <HTMLSelect fill defaultValue={lcn.type} onChange={(e) => setValue("type", e.currentTarget.value)}>
                        <option>{t('choose_an_item')}</option>
                        <option value="full">{t('full')}</option>
                        <option value="education">{t('education')}</option>
                    </HTMLSelect>
                </FormGroup>
                {!isNew && <FormGroup className="Col-5-1" label={t('print')} labelFor="inputPrint">
                    <InputGroup id="inputPrint" name="print" inputRef={register} />
                </FormGroup>}
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label={t('app')} labelFor="inputApp">
                    {/*<InputGroup id="inputApp" name="app" inputRef={register} />*/}
                    <HTMLSelect fill defaultValue={lcn.app} onChange={(e) => setValue("app", e.currentTarget.value)}>
                        <option>{t('choose_an_item')}</option>
                        <option value="AC3x">AC3x</option>
                    </HTMLSelect>
                </FormGroup>
                <FormGroup className="Col-2-1" label={t('version')} labelFor="inputVersion">
                    {/*<InputGroup id="inputVersion" name="version" inputRef={register} />*/}
                    <HTMLSelect fill defaultValue={lcn.version} onChange={(e) => setValue("version", e.currentTarget.value)}>
                        <option>{t('choose_an_item')}</option>
                        <option value="1">1</option>
                    </HTMLSelect>
                </FormGroup>
                <FormGroup className="Col-3-1" label={t('edition')} labelFor="inputEdition">
                    {/*<InputGroup id="inputEdition" name="edition" inputRef={register} />*/}
                    <HTMLSelect fill defaultValue={lcn.edition} onChange={(e) => setValue("edition", e.currentTarget.value)}>
                        <option>{t('choose_an_item')}</option>
                        <option value="standalone">{t('standalone')}</option>
                        <option value="network">{t('network')}</option>
                    </HTMLSelect>
                </FormGroup>
            </div>
            {/*<div className="Row">
                <FormGroup className="Col-1-1" label={t('region')} labelFor="inputRegion">
                    <HTMLSelect fill defaultValue={lcn.region} onChange={(e) => setValue("region", e.currentTarget.value)}>
                        <option>{t('choose_an_item')}</option>
                        <option value="all">{t('all')}</option>
                    </HTMLSelect>
                </FormGroup>
                <FormGroup className="Col-2-1" label={t('channel')} labelFor="inputChannel">
                    <HTMLSelect fill defaultValue={lcn.channel} onChange={(e) => setValue("channel", e.currentTarget.value)}>
                        <option>{t('choose_an_item')}</option>
                        <option value="direct">{t('direct')}</option>
                        <option value="reseller">{t('reseller')}</option>
                    </HTMLSelect>
                </FormGroup>
            </div>*/}
            <div className="Row">
                <FormGroup className="Col-1-1" label={t('channel')} labelFor="inputChannel">
                    <HTMLSelect fill defaultValue={lcn.channel} onChange={(e) => setValue("channel", e.currentTarget.value)}>
                        <option>{t('choose_an_item')}</option>
                        <option value="direct">{t('direct')}</option>
                        <option value="reseller">{t('reseller')}</option>
                    </HTMLSelect>
                </FormGroup>
                <FormGroup className="Col-2-1" label={t('partner')} labelFor="inputPartner">
                    <InputGroup id="inputPartner" name="partner" inputRef={register} />
                </FormGroup>
                <FormGroup className="Col-3-1" label={t('reference')} labelFor="inputReference">
                    <InputGroup id="inpinputReferenceutType" name="reference" inputRef={register} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label={t('remark')} labelFor="inputRemark">
                    <InputGroup id="inputRemark" name="remark" inputRef={register} />
                </FormGroup>
            </div>
            {!isNew && <div className="Row">
                <FormGroup className="Col-1-1" label={t('status')} labelFor="inputStatus">
                    {/*<InputGroup id="inputStatus" name="status" inputRef={register} />*/}
                    <HTMLSelect fill defaultValue={lcn.status} onChange={(e) => setValue("status", e.currentTarget.value)}>
                        <option value=" ">{t('unregistered')}</option>
                        <option value="R">{t('registered')}</option>
                        <option value="L">{t('locked')}</option>
                    </HTMLSelect>
                </FormGroup>
                <FormGroup className="Col-2-1" label={t('registration_date')} labelFor="inputRegistrationDate">
                    <InputGroup type="date" id="inputRegistrationDate" name="registrationDate" inputRef={register} />
                </FormGroup>
                <FormGroup className="Col-3-2" label={t('email')} labelFor="inputEmail">
                    <InputGroup id="inputEmail" name="email" inputRef={register} />
                </FormGroup>
            </div>}
            {isNew && <div className="Row">
                <FormGroup className="Col-1-1" label={t('copies')} labelFor="inputCopies">
                    <InputGroup type="number" id="inputCopies" value={copies.toString()} onChange={utils.handleNumberChange(n => setCopies(n))} />
                </FormGroup>
            </div>}
        </form>
    )
}

interface LicenseMainProps {
    svc: AxiosInstance
    currentEnv?: Environment,
}

function LicenseMain({ svc, currentEnv = {} as Environment }: LicenseMainProps) {
    let { tabId: defaultTabId = "list" }: { tabId: string } = useParams()
    const { t, i18n } = useTranslation();
    const history = useHistory()

    const [data, setData] = useState([] as License[])

    const [searchKey, setSearchKey] = useState("issueDate")
    const [searchValue, setSearchValue] = useState("")
    let date = new Date(), y = date.getFullYear(), m = date.getMonth()
    const [searchDate, setSearchDate] = useState(utils.formatDate(new Date(y, m-1, 1)))

    const [showFilters, setShowFilters] = useState(true)

    const [searchLimit, setSearchLimit] = useState(300)

    const [showLabels, setShowLabels] = useState(false)
    const [skipLabels, setSkipLabels] = useState(0)
    const [showAdvanced, setShowAdvanced] = useState(false)

    const [searchReference, setSearchReference] = useState("")

    const [selectedTabId, setSelectedTabId] = useState(defaultTabId) //useState("list")
    const [currentAc, setCurrentAc] = useState({} as License)

    const [currentMode, setCurrentMode] = useState("show")
    const [lastTab, setLastTab] = useState(defaultTabId)

    const [isOpenDelete, setIsOpenDelete] = useState(false)
    const [isOpenMarkPrint, setIsOpenMarkPrint] = useState(false)

    useEffect(() => {
        setSelectedTabId(defaultTabId)
    }, [defaultTabId])

    const changeTab = (tabId: string) => {
        history.push(`/licenses/${tabId}`)
        setSelectedTabId(tabId)
    }

    useEffect(() => {
        (async () => {
            refreshLicenses()
        })()
    }, [currentEnv])

    useEffect(() => {
        if (data.length > 0) {
            let i = 0;
            if ("licenseId" in currentAc) {
                i = data.findIndex(lcn => lcn.licenseId === currentAc.licenseId)
            }
            setCurrentAc(i > 0 ? data[i] : data[0])
        }
    }, [data])

    const refreshLicenses = async () => {
        if ("envId" in currentEnv) {
            try {
                const res = await svc.post('/licenselist',
                    {
                        by: searchKey,
                        start: searchKey === "issueDate" ? searchDate : searchValue,
                        limit: searchLimit,
                        filters: {
                            reference: searchReference
                        }
                    });

                setData(res.data.data)
                if (res.data.data.length === 0) {
                    utils.showError(t('no_record'))
                }
                //changeTab("list")
            } catch (error) {
                utils.showError(error)
            }    
        }
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()
        refreshLicenses()
    }

    const editOnSave = async (lcn: License) => {
        try {
            const res = await svc.post('/licenseupdate', lcn)
            utils.showSuccess(t('saved'))
            for (let i = 0; i < data.length; i++) {
                if (data[i].licenseId === res.data.data.licenseId) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    //setCurrentAc(lcn)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const newOnSave = async (lcn: License) => {
        try {
            const res = await svc.post('/licenseadd', lcn)
            refreshLicenses()
            utils.showSuccess(t('saved'))
            setCurrentAc(res.data.data[0])
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const viewOnDelete = async (lcn: License) => {
        try {
            //console.log("deleting account:", vch)
            const res = await svc.post('/licensedelete', lcn)
            utils.showSuccess(t('deleted'))
            setData(data.filter(lcn => !(lcn.licenseId === res.data.licenseId)))
            setCurrentAc({} as License)
            changeTab("list")
        } catch (error) {
            utils.showError(error)
        }
    }

    const viewOnUnregister = async (lcn: License) => {
        try {
            //console.log("deleting account:", vch)
            const res = await svc.post('/licenseunregister', lcn)
            utils.showSuccess(t('done'))
            for (let i = 0; i < data.length; i++) {
                if (data[i].licenseId === res.data.data.licenseId) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    //setCurrentAc(lcn)
                    break
                }
            }
            setCurrentAc(res.data.data)
            //changeTab("list")
        } catch (error) {
            utils.showError(error)
        }
    }

    const onDelete = async (licenses: License[]) => {
        try {
            //console.log("deleting account:", vch)
            const res = await svc.post('/licensedeletelist', {licenses: licenses})
            utils.showSuccess(t('deleted'))
            setData([])
            //setData(data.filter(lcn => !(lcn.licenseId === res.data.licenseId)))
            setCurrentAc({} as License)
            changeTab("list")
        } catch (error) {
            utils.showError(error)
        }
    }

    const onMarkPrint = async (licenses: License[]) => {
        try {
            //console.log("deleting account:", vch)
            const res = await svc.post('/licensemarkprintlist', {licenses: licenses})
            utils.showSuccess(t('done'))
            setData(res.data.data)
            //setData(data.filter(lcn => !(lcn.licenseId === res.data.licenseId)))
            setCurrentAc({} as License)
            changeTab("list")
        } catch (error) {
            utils.showError(error)
        }
    }

    // currentMode = show
    let detail = <LicenseView lcn={currentAc} baseUrl={svc.defaults.baseURL} token={localStorage.getItem('token')} onEdit={() => setCurrentMode("edit")} onNew={() => {console.log(selectedTabId); setLastTab(selectedTabId); setCurrentMode("new")}} onDelete={viewOnDelete} onUnregister={viewOnUnregister}></LicenseView>
    switch (currentMode) {
        case "edit":
            detail = <LicenseEdit lcn={currentAc} onSave={editOnSave} onCancel={() => {setCurrentMode("show")}}></LicenseEdit>
            break
        case "new":
            detail = <LicenseEdit isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); changeTab(lastTab) }}></LicenseEdit>
            break
    }

    let list = <>
        <Alert icon="trash" intent="danger" confirmButtonText={t('button_delete')} isOpen={isOpenDelete} onConfirm={() => {onDelete(data); setIsOpenDelete(false)}} onCancel={() => setIsOpenDelete(false)} cancelButtonText={t('button_no')} canEscapeKeyCancel={true} canOutsideClickCancel={true}>
            <p>{t('confirm_delete_license_list', {licenses: data})}</p>
        </Alert>
        <Alert icon="print" intent="danger" confirmButtonText={t('button_yes')} isOpen={isOpenMarkPrint} onConfirm={() => {onMarkPrint(data); setIsOpenMarkPrint(false)}} onCancel={() => setIsOpenMarkPrint(false)} cancelButtonText={t('button_no')} canEscapeKeyCancel={true} canOutsideClickCancel={true}>
            <p>{t('confirm_mark_print_license_list', {licenses: data})}</p>
        </Alert>
        <div className="Row">
            <Button className="Col-1-1" icon="add" onClick={() => {setLastTab(selectedTabId); setCurrentMode("new"); changeTab("detail") }}>{t('new')}</Button>
            <Button className="Col-2-1" icon="print" onClick={() => {setIsOpenMarkPrint(true)}}>{t('button_mark_print_all')}</Button>
            <Checkbox className="Col-4-1 Checkbox-InlineFormGroup" label="Advanced" onChange={utils.handleBooleanChange(v => setShowAdvanced(v))} />
            {showAdvanced && <Button className="Col-5-1" icon="trash" intent="danger" onClick={() => {setIsOpenDelete(true)}}>{t('button_delete_all')}</Button>}
        </div>
        <div className="Spacing-V-16" />
        <HTMLTable striped interactive condensed>
            <thead>
                <tr>
                    <th className="TCol-Date">{t('issue_date')}</th>
                    <th className="TCol-License">{t('license_id')}</th>
                    <th className="TCol-App">{t('app')}</th>
                    <th className="TCol-Version">{t('version')}</th>
                    <th className="TCol-Edition">{t('edition')}</th>
                    <th className="TCol-Type">{t('type')}</th>
                    {/*<th className="TCol-Region">{t('region')}</th>*/}
                    <th className="TCol-Channel">{t('channel')}</th>
                    <th className="TCol-Partner">{t('partner')}</th>
                    <th className="TCol-Reference">{t('reference')}</th>
                    <th className="TCol-Print">{t('print')}</th>
                    <th className="TCol-Status">{t('status')}</th>
                    <th className="TCol-Email">{t('email')}</th>
                </tr>
            </thead>
            <tbody>
                {data.map((lcn, i) =>
                    <tr key={i} onDoubleClick={() => { changeTab("detail") }}>
                        <td className="TCol-Date" onClick={() => { setCurrentAc(lcn) }}>{lcn.issueDate}</td>
                        <td className="TCol-License" onClick={() => { setCurrentAc(lcn) }}><div className="bp3-monospace-text">{lcn.licenseId}</div></td>
                        <td className="TCol-App" onClick={() => { setCurrentAc(lcn) }}>{lcn.app}</td>
                        <td className="TCol-Version" onClick={() => { setCurrentAc(lcn) }}>{t(lcn.version)}</td>
                        <td className="TCol-Edition" onClick={() => { setCurrentAc(lcn) }}>{t(lcn.edition)}</td>
                        <td className="TCol-Type" onClick={() => { setCurrentAc(lcn) }}>{t(lcn.type)}</td>
                        {/*<td className="TCol-Region" onClick={() => { setCurrentAc(lcn) }}>{t(lcn.region)}</td>*/}
                        <td className="TCol-Channel" onClick={() => { setCurrentAc(lcn) }}>{t(lcn.channel)}</td>
                        <td className="TCol-Partner" onClick={() => { setCurrentAc(lcn) }}>{lcn.partner}</td>
                        <td className="TCol-Reference" onClick={() => { setCurrentAc(lcn) }}>{lcn.reference}</td>
                        <td className="TCol-Print" onClick={() => { setCurrentAc(lcn) }}>{lcn.print}</td>
                        <td className="TCol-Status" onClick={() => { setCurrentAc(lcn) }}>{t(utils.statusName(lcn.status))}</td>
                        <td className="TCol-Email" onClick={() => { setCurrentAc(lcn) }}>{lcn.email}</td>
                    </tr>
                )}
            </tbody>
        </HTMLTable>
    </>

    function ArrayWithCount(length: number) {
        let a = new Array(length); for (let i=0; i<length; ++i) a[i] = i;
        return a;
    }

    function BuildLicenseLabels(licenses: License[], skip: number): License[] {
        let a = new Array(skip); for (let i=0; i<skip; ++i) a[i] = {licenseId: ""} as License;
        licenses.map(lcn => {a.push({...lcn, ...{licenseId: lcn.licenseId.slice(0,8)+"X".repeat(8)}}); a.push(lcn)})
        /*
        for (let i=0; i<licenses.length; ++i) {
            a.push({...licenses[i], ...{licenseId: "XXXXXXXXXXXX"}})
            a.push(licenses[i])
        }
        */
        return a;
    }

    function LicenseLabelHeader(lcn: License) {
        return lcn.app && lcn.issueDate.replace(/-/g, "")+"."+lcn.app+"."+lcn.version+lcn.edition.slice(0, 1).toUpperCase()+"."+lcn.partner.toUpperCase()
    }

    let newData = BuildLicenseLabels(data, skipLabels)
    let labels = <>
        {ArrayWithCount(Math.ceil(newData.length / 64)).map(i => 
        <div className="A3667">
            {newData.slice(i*64,(i+1)*64).map((lcn, i) => <>
                <div className="bp3-monospace-text">
                    <div className="Font-XS">{LicenseLabelHeader(lcn)}</div>
                    <div>{utils.formatLicenseId(lcn.licenseId, false)}</div>
                </div>
            </>)}
        </div>
        )}
    </>

    return (
        <>
            <div className="No-Print" style={{fontSize: "21px", fontWeight: 700}}>{t('licenses')}</div>
            <div className="No-Print Spacing-V-16"></div>
            <form className="No-Print" onSubmit={onSubmit}>
                <div className="Row">
                    <FormGroup className="Col-1-3" label={t('search')} labelFor="inputSearchValue">
                        <ControlGroup fill>
                            <HTMLSelect className={Classes.FIXED} defaultValue={searchKey} onChange={utils.handleStringChange(s => setSearchKey(s))}>
                                <option value="issueDate">{t('issue_date')}</option>
                                <option value="email">{t('email')}</option>
                                <option value="licenseId">{t('license_id')}</option>
                                <option value="_recordNo">Record No.</option>
                            </HTMLSelect>
                            {searchKey === "issueDate" && <InputGroup
                                type="date"
                                id="inputSearchDate"
                                value={searchDate}
                                onChange={utils.handleStringChange(s => setSearchDate(s))}
                                leftIcon="search"
                                rightElement={<Button icon={IconNames.ARROW_RIGHT} minimal type="submit"></Button>}
                            />}
                            {searchKey !== "issueDate" && <InputGroup
                                type="text"
                                id="inputSearchValue"
                                placeholder={t('search')}
                                value={searchValue}
                                onChange={utils.handleStringChange(s => setSearchValue(s))}
                                leftIcon="search"
                                rightElement={<Button icon={IconNames.ARROW_RIGHT} minimal type="submit"></Button>}
                            />}
                        </ControlGroup>
                    </FormGroup>
                    {/*<Checkbox className="Col-4-1 Checkbox-LabeledFormGroup" label="Backward" onChange={utils.handleBooleanChange(v => setSearchBackward(v))} />*/}
                    {/*<Button className="Col-5-1 Button-LabeledFormGroup" icon="filter" onClick={() => { setShowFilters(!showFilters) }} >Filters</Button>*/}
                </div>
                <Collapse isOpen={showFilters}>
                    <div className="Row">
                        <FormGroup className="Col-1-1" label={t('reference')} labelFor="searchIdNo">
                            <InputGroup id="searchReference" value={searchReference} onChange={utils.handleStringChange(s => setSearchReference(s))} />
                        </FormGroup>
                        <div className="Col-2-2 Col-H">
                            <FormGroup label="Limit" labelFor="searchLimit" >
                                <InputGroup className="Input-XS" id="searchLimit" value={searchLimit.toString()} onChange={utils.handleNumberChange(n => setSearchLimit(n))} />
                            </FormGroup>
                        </div>
                    </div>
                </Collapse>
                <div className="Row">
                    <Checkbox className="Col-1-1 Checkbox-InlineFormGroup" label="Labels" onChange={utils.handleBooleanChange(v => setShowLabels(v))} />
                    <FormGroup className="Col-2-1" inline label="Skip" labelFor="skipLabels" >
                        <InputGroup className="Input-XS" id="skipLabels" value={skipLabels.toString()} onChange={utils.handleNumberChange(n => setSkipLabels(n))} />
                    </FormGroup>
                </div>
            </form>
            {!showLabels && <Tabs onChange={(newTabId) => changeTab(newTabId as string)} selectedTabId={selectedTabId}>
                <Tab id="list" title={t('list')} panel={list} />
                <Tab id="detail" title={t('detail')} panel={detail} />
            </Tabs>}
            {showLabels && labels}
        </>
    );
}

export default LicenseMain